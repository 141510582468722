import React from 'react';
import Typography from '@mui/material/Typography';
import NestTooltip from 'src/components/NestTooltip/NestTooltip';
import { formatAmount } from '../../../../../common/helpers/number-helper';
import { grayMainColor, textColorPrimary } from '../../../../../theme/palette/palette';
import { ICalculatedLimit } from '../OvdCalculatorPage';

interface IOvdLimitSimulatorCalculatorProps {
	markUp: number;
	wibor: number;
	calculatedLimit: ICalculatedLimit;
}

export const OvdLimitSimulatorCalculator = (props: IOvdLimitSimulatorCalculatorProps): JSX.Element => {
	const countInterests = (): number => {
		const sumOfPercentage = props.markUp + props.wibor;
		const sumOfYear = (props.calculatedLimit.percentValue * sumOfPercentage) / 100;
		const sumOfDay = sumOfYear / 360;
		return Math.round(sumOfDay * props.calculatedLimit.daysValue);
	};
	return (
		<Typography
			variant='h4'
			sx={{
				fontSize: { xs: '20px', md: '24px' },
				lineHeight: { xs: '24px', md: '28px' },
				fontWeight: '400',
				maxWidth: { xs: '100%', md: '94%' },
				padding: '24px',
				height: 'auto',
				marginTop: { xs: '32px', md: '44px' },
				marginBottom: { xs: '34px', md: '74px' },
				background: '#AFFFD1',
				color: grayMainColor,
			}}
		>
			Wykorzystując z przyznanego limitu
			<Typography variant='h4' component={'span'} sx={{ color: textColorPrimary }}>
				<b> {formatAmount(props.calculatedLimit.percentValue)} PLN </b>
			</Typography>
			przez okres
			<Typography variant='h4' component={'span'} sx={{ color: textColorPrimary }}>
				<b>
					{' '}
					{props.calculatedLimit.daysValue} dni
					{props.calculatedLimit.daysValue === 1 && 'a'}{' '}
				</b>
			</Typography>
			Twoje odsetki wyniosą jedynie{' '}
			<Typography variant='h4' component={'span'} sx={{ color: textColorPrimary }}>
				<b>{countInterests()} PLN </b>
			</Typography>
			<NestTooltip
				sxTooltipContent={{ maxWidth: '630px', padding: '0 16px' }}
				text='Przedstawiona w kalkulacji szacunkowa wysokość odsetek obliczana jest na dzień jej publikacji na dedykowanej stronie internetowej i może się różnić od ostatecznej wysokości. Wskazana kwota uwzględnia szacunkową wysokość odsetek wyliczoną na podstawie parametrów podanych przez przedsiębiorcę na dedykowanej stronie internetowej i ma wartość zaokrągloną do pełnych złotych oraz została wyliczona przy założeniu, że kwota wykorzystanego limitu nie uległa zmianie w okresie podanym w kalkulacji.'
			/>
		</Typography>
	);
};
