const getTextAmount = (amount: number): string => {
	if(!amount){
		return '300 tys PLN'
	}
	if(amount < 1000){
		return `${amount} PLN`
	}
	if(amount < 1000000){
		return `${amount/1000} tys. PLN`
	}
	return `${amount/1000000} mln PLN`
}

export const ovdLegalNote = (maxAmount: number): string => 
	`<div style="font-weight: normal"><p><div>Przedstawiona propozycja kredytu ma charakter wstępny i nie ma charakteru oferty w` +
	` rozumieniu art. 66 Kodeksu cywilnego. O <b>BIZnest Limit w Koncie</b> oferowany przez Nest Bank S.A. („Bank”),` +
	` wnioskować może:</div>` +
	`<div>- przedsiębiorca, będący  osobą fizyczną prowadzącą indywidualną działalność gospodarczą (który   prowadzi swoją działalność` +
	` przez minimum 24 miesiące poprzedzające dzień złożenia wniosku o udzielenie limitu - maksymalna kwota limitu to ${getTextAmount(maxAmount)})</div>` +
	`<div>- profesjonalista, będący osobą  wykonującą wolny zawód  taki jak: lekarz, stomatolog, lekarz weterynarii, adwokat, radca prawny, notariusz lub fizjoterapeuta (aby móc starać się o BIZNest Limit w Koncie,  profesjonalista powinien wykonywać wolny zawód przez okres co najmniej 24 miesięcy poprzedzający dzień złożenia wniosku o udzielenie Limitu, przy czym w okresie minimum 12 miesięcy przed datą złożenia wniosku, niezbędnym jest uzyskiwanie dochodu w ramach indywidualnej działalności gospodarczej - maksymalna kwota limitu to ${getTextAmount(maxAmount)}).</div>` +
	`<div>Udzielenie limitu oraz jego wysokość uzależnione są od pozytywnej oceny zdolności kredytowej przedsiębiorcy.</p></div>` +
	`<p>Szczegóły oferty, w tym „Regulamin kredytowania działalności gospodarczej Nest Banku, linia mikro i małych przedsiębiorstw”, „Tabela Opłat i Prowizji dla Klienta biznesowego – oferta kredytowa” oraz „Tabela Oprocentowania dla Klienta biznesowego” – oferta kredytowa” dostępne są na <a style="color: #BCA8FF" href="nestbank.pl">www.nestbank.pl</a>, w placówkach własnych i u partnerów finansowych banku.</p></div>`;
