import Stack from '@mui/material/Stack';
import { allClientFormCodes } from '../../../common/api/ErrorCodes';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useFetch from 'src/common/api/UseFetch';
import { resolveIndexTokenByType } from '../../common/index/IndexTokenResolver';
import LeadForm from '../../components/LeadForm/LeadForm';
import { ILeadForm } from '../../components/LeadForm/LeadFormConstants';
import LeadStart from '../../components/LeadStart/LeadStart';
import { LeadProductType } from '../../components/leadTypes';
import { AgreementModelToSendLead, processLeadConfig } from '../common/LeadApi';
import { LeadEndRejectionRoute, LeadEndRoute, LeadTechnicalErrorRoute } from 'src/routing/Routing';
import { ILeadInitialData } from '../lead-new-application/LeadNewApplicationApi';
import { ovdLegalNote } from './LeadOVDConst';
import { useEffect, useState } from 'react';
import { getPartnerLogoLead } from '../../../common/helpers/get-partner-logo-helper';
import { interpolatePath } from 'src/routing/RoutingService';
import { IGoogleAnalyticsData } from 'src/common/models/googleAnalyticsDataModels';

interface LeadOvdProps {
	initialData: ILeadInitialData;
}

export default function LeadOVD({ initialData }: LeadOvdProps): JSX.Element {
	const [partnerLogoLink, setPartnerLogoLink] = useState('');
	const params = useParams();
	const navigate = useNavigate();
	const urlParamA = new URLSearchParams(useLocation().search).get('a');

	useEffect((): void => {
		if (initialData.logoUrl) {
			setPartnerLogoLink(getPartnerLogoLead(initialData.logoUrl));
		}
	}, []);

	const processLead = useFetch(
		processLeadConfig,
		false,
		(resp): void => {
			const googleAnalyticsData: IGoogleAnalyticsData = {
				applicationId: resp.applicationId,
				userId: resp.userId,
				partnerId: new URL(window.location.href).searchParams.get('Partner_ID'),
			};
			navigate(interpolatePath(LeadEndRoute.path, { guid: params.guid ?? resolveIndexTokenByType()?.token }), { state: googleAnalyticsData });
		},
		false,
		(error): void => {
			if (allClientFormCodes.includes(error.message)) {
				const googleAnalyticsData: IGoogleAnalyticsData = {
					applicationId: '',
					userId: '',
					partnerId: new URL(window.location.href).searchParams.get('Partner_ID'),
				};
				navigate(interpolatePath(LeadEndRejectionRoute.path, { guid: params.guid ?? resolveIndexTokenByType()?.token }), {
					state: googleAnalyticsData,
				});
			} else {
				navigate(interpolatePath(LeadTechnicalErrorRoute.path, { guid: params.guid ?? resolveIndexTokenByType()?.token }));
			}
		},
		(): void => {
			navigate(
				interpolatePath(LeadTechnicalErrorRoute.path, {
					guid: params.guid ?? resolveIndexTokenByType()?.token,
				})
			);
		},

		false
	);

	const onSubmit = (data: ILeadForm, agreements: AgreementModelToSendLead[], recaptchaSecretId: string): void => {
		processLead.mutate({
			campaignNumber: initialData.campaignNumber,
			sourceId: initialData.sourceId,
			creditAmount: initialData.parameters.maxAmount,
			brokerId: initialData.brokerId,
			brokerDescription: initialData.brokerDescription,
			firstName: data.firstName ?? '',
			lastName: data.lastName ?? '',
			phoneNumber: data.phoneNumber ? data.phoneNumber : null,
			nip: data.nip ?? '',
			agreements: agreements,
			ReCaptcha: recaptchaSecretId,
			...(urlParamA ? { customField: urlParamA } : {}),
		});
	};
	
	return (
		<Stack sx={{ width: '100%', flexDirection: { xs: 'column', md: 'row' } }}>
			<LeadStart
				leadProductType={LeadProductType.Overdraft}
				minLoanAmount={initialData.parameters.minAmount}
				maxLoanAmount={initialData.parameters.maxAmount}
				periods={initialData.parameters.periods}
				commission={initialData.parameters.commission}
				wibor={initialData.wibor}
				legalNote={ovdLegalNote(initialData.parameters.maxAmount)}
				logoUrl={partnerLogoLink}
			/>

			<LeadForm onSubmit={onSubmit} isLoading={processLead.isPending} showCaptcha={initialData.showCaptcha} />
		</Stack>
	);
}
