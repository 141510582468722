import { CSSProperties } from '@mui/material/styles/createMixins';
import { styled } from '@mui/system';
import { mainTheme } from '../../../theme/mainTheme';
import { LeadProductType } from '../leadTypes';

interface IHeroImage {
	productType: LeadProductType;
}

export const HeroImage = styled(
	'img',
	{}
)<IHeroImage>(
	(props: IHeroImage): CSSProperties => ({
		height: props.productType === LeadProductType.Overdraft ? '153%' : '100%',
		zIndex: '1',
		marginTop:
			props.productType === LeadProductType.Overdraft ? '-30px' : '15px',
		marginLeft:
			props.productType === LeadProductType.Overdraft ? '-38px' : '15px',
		transform: 'scale(0.95)',
	})
);

export const LogoPartnerContainer = styled('div')({
	padding: 0,
	width: '150px',
	height: '70px',
	display: 'flex',
	flexDirection: 'column',
	[mainTheme.breakpoints.up(395 as number)]: {
		padding: '0 0 0 16px',
	},
})

export const LogoContainer = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	[mainTheme.breakpoints.down(395 as number)]: {
		width: '170px',
		height: '84px',
	},
	[mainTheme.breakpoints.up(395 as number)]: {
		width: '207px',
		height: '97px',
	},
})
